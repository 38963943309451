import katex from 'katex';

export default {
  data () {

  },
  methods: {
    // 题干渲染
    renderQuestionStem (questionStem = []) {
      questionStem.map((e,n)=>{
        if(e[0] ===  'tex'){
          let dom = document.getElementById('tex_item_'+n);
          this.$kx.render(e[1], dom, {
            displayMode: true,
          });
        }
      })
    },

    // 选择题选项渲染
    renderSelectOption (formula, ref) {
      if (formula.length === 0 || !ref) {
        return null
      }
      const SELECT_TYPE = ['A', 'B', 'C', 'D']
      formula.forEach((item, index) => {
        const itemDom = document.createElement('div');
        itemDom.className = 'select-option__item'
        const spanDom = document.createElement('span');
        const katexDom = document.createElement('span');
        spanDom.innerText = `${SELECT_TYPE[index]} `
        katex.render(item, katexDom, {
          throwOnError: false
        });
        itemDom.appendChild(spanDom)
        itemDom.appendChild(katexDom)
        itemDom.onclick = () => this.handleSelectOption(item, index);
        ref.appendChild(itemDom)
      });
    },
  }
}
