/* 提交方法混合 */
import { CATALOG_ID_ENUM } from '@/consts';

export default {
  methods: {
    async submit (param) {
      try {
        const routerParams = this.$route.query
        await this.$api.resultSave({
          param: {
            ...param,
            math_type: routerParams.mathType,
            book: routerParams.book,
            level: routerParams.level,
            questionID: routerParams.questionID,
            chapterIdx: routerParams.chapterIndex,
            sectionIdx: routerParams.sectionIndex,
            questionIdx: routerParams.questionIdx,
            catalog_id: CATALOG_ID_ENUM[ routerParams.type ]
          }
        })
        return true
      } catch (err) {
        console.error(err)
        return false
      }
    }
  }
}
